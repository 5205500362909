import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import {getLoginInfoFromCookie, makeAuthenticatedRequest} from './utils';
import LoginModule from '../components/glogin';
import { Container, Spinner, Form, Card } from 'react-bootstrap';

function ReportsList() {
  const [clientSystemId, setClientSystemId] = useState(null);
  const [loginState, setLoginState] = useState(null);
  const [cSearchParams] = useSearchParams();

  const queryClientSystemId = cSearchParams.get('client_system_id');

  const loginCallback = (data) => {
    if (data.workspaces.length > 0) {
      setClientSystemId(queryClientSystemId || data.workspaces[0].client_system_id);
      setLoginState('logged_in');
    } else {
      console.error('No workspaces found for the user');
      setLoginState('not_logged_in');
      window.location.href = '/';
    }
  }

  const loginFailureCallback = () => {
    window.location.href = '/';
    setLoginState('not_logged_in');
  }  

  useEffect(() => {
    if (loginState === null) {
      getLoginInfoFromCookie(loginCallback, loginFailureCallback);
    }
  }, [])

  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('all');

  const fetchReports = (clientSystemId) => {
    setLoading(true);
    if (!clientSystemId) {
      return;
    }

    return makeAuthenticatedRequest(`/api/reports?client_system_id=${clientSystemId}`)
      .then(response => response.json())
      .then(data => {
        setReports(data['reports']);
        setLoading(false);
        //setUserCredits(data['user_credits']);
      });
  }

  useEffect(() => {
    let uniqueDates = [...new Set(reports.map(report => new Date(report.created_at).toDateString()))];
    setAvailableDates(uniqueDates);
  }, [reports]);

  useEffect(() => {
    let latestDate = new Date(Math.max.apply(null, reports.map(report => new Date(report.created_at))));
    setSelectedDate(latestDate.toDateString());
  }, [reports]);

  const getFilteredReports = () => {
    if (selectedDate === 'all') {
      return reports;
    }
    return reports.filter(report => new Date(report.created_at).toDateString() === selectedDate);
  };

  useEffect(() => {
    fetchReports(clientSystemId);
  }, [clientSystemId]);

  const getReportLink = (report) => {
    if (queryClientSystemId) {
      return `/reports/${report.id}?client_system_id=${queryClientSystemId}`;
    }
    return `/reports/${report.id}`;
  };

  if (!loginState) {
    return "";
  }

  if (!clientSystemId && loginState === 'not_logged_in'){
    return <LoginModule onSuccessCallback={loginCallback} />;
  }

  return (
    <Container fluid className="container py-4 bg-light d-flex flex-column">
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="h3">Daily Reports</h1>
          <div className="d-flex gap-3 align-items-center">
            {/* Existing Service Filter */}
            <Form.Select
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              style={{ minWidth: '140px' }}
            >
              <option value="all">All Dates</option>
              {availableDates.map(availableDate => (
                <option key={availableDate} value={availableDate}>{availableDate}</option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 overflow-auto">
        {loading ? (
          <div className="text-center py-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : getFilteredReports().length === 0 ? (
          <div className="text-center py-5">
            <p className="text-muted">No Reports Available</p>
          </div>
        ) : (
          <div className="d-flex flex-column gap-3">
            {getFilteredReports().map(report => (
              <div id={`report-${report.id}`} key={report.id}>
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <a href={getReportLink(report)}>{report.title || report.id}</a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
}

export default ReportsList
