import { useEffect, useState } from 'react'
import { Form, Button, InputGroup, Card } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons';

import { useParams } from 'react-router-dom'
import { formatDateTime } from './utils';

import { IoSearch } from 'react-icons/io5';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import Sidebar from '../components/Sidebar';
import IssueDetailsPage from '../components/issue_details/issue_details';
import LoginModule from '../components/glogin';

import '../components/demo.css';
import {getLoginInfoFromCookie, makeAuthenticatedRequest} from './utils';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ListView = (props) => {
  return (
    <div className="d-flex flex-column gap-1">
      {props.items.map((item, index) => (
        <ListItemView 
          key={index} 
          item={item} 
          indx={index} 
          selectedIndx={props.selectedIssue} 
          selectHandler={props.selectHandler} 
        />
      ))}
    </div>
  );
}

const ListItemView = (props) => {
  const getIssueSummary = () => {
    return props.item.summary;
  }

  const isSelected = props.selectedIndx === props.indx;

  return (
    <Card 
      onClick={() => { props.selectHandler(props.item.id) }}
      className={`${isSelected ? 'border-primary' : ''}`}
      style={{ 
        cursor: isSelected ? "default" : "pointer",
        transition: 'all 0.2s ease-in-out'
      }}
    >
      <Card.Body className="py-1 px-3">
        <div className="d-flex flex-column">
          <span 
            className={`fw-medium ${isSelected ? 'text-primary' : ''}`}
            style={{ fontSize: '0.9rem' }}
          >
            {getIssueSummary().substring(0, 80)}
            {getIssueSummary().length > 80 ? '...' : ''}
          </span>
          <small className="text-muted" style={{ fontSize: '0.7rem' }}>
            {formatDateTime(props.item.created)}
          </small>
        </div>
      </Card.Body>
    </Card>
  );
}

const DetailView = (props) => {
  if (!props.item){
    return "";
  }
  const refreshCallback = () => {};

  return (
    <div>
      <IssueDetailsPage item={props.item} refreshCallback={refreshCallback} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />
    </div>
   );
}

const Container = ({reportId}) => {
  const [loginState, setLoginState] = useState(null);

  const [clientSystemId, setClientSystemId] = useState(null);
  const [issues, setIssues] = useState([])
  const [selectedIssue, setSelectedIssue] = useState(null) // null means creating new issue
  const [orgId, setOrgId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [cSearchParams] = useSearchParams();

  const queryClientSystemId = cSearchParams.get('client_system_id');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const fetchIssues = () => {
    if (!clientSystemId) {
      return;
    }

    return makeAuthenticatedRequest(`/api/reports/${reportId}?client_system_id=${clientSystemId}`)
      .then(response => response.json())
      .then(data => {
        setIssues(data['issues']);
        //setUserCredits(data['user_credits']);
        return data['issues'];
      });
  }

  const handleIssueSelect = (issueId) => {
    var index = null;
    for (let i = 0; i < issues.length; i++) {
      if (issues[i].id === issueId) {
        index = i;
        break;
      }
    }
    setSelectedIssue(index);
    const selectedIssueId = issues[index]?.id;
    if (selectedIssueId) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('issue_id', selectedIssueId.toString());
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  }

  const getFilteredIssues = () => {
    if (!searchTerm) {
      return issues;
    }
    if (searchTerm.length  === 0) {
      return issues;
    }

    let filteredIssues = []
    for (let i = 0; i < issues.length; i++) {
      if (issues[i].summary.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredIssues.push(issues[i])
      } else if (issues[i].description.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredIssues.push(issues[i])
      } else if (i === selectedIssue) {
        filteredIssues.push(issues[i])
      }
    }
    return filteredIssues;
  }

  const selectIssueFromId = (issueId) => {
    handleIssueSelect(issueId);
  }

  useEffect(() => {
    fetchIssues();
  }, [clientSystemId]);

  useEffect(() => {
    if (issues.length > 0){
      setSelectedIssue(0);
    }
  }, [issues]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const issueId = searchParams.get('issue_id');

    const fetchAndSetIssues = () => {
      try {
        if (issueId) {
          const numericIssueId = parseInt(issueId, 10);
          const issueIndex = issues.findIndex(issue => issue.id === numericIssueId);
          if (issueIndex !== -1) {
            setSelectedIssue(issueIndex);
          } else {
            console.log(`Issue with id ${numericIssueId} not found`);
          }
        } 
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    };

    fetchAndSetIssues();
  }, [clientSystemId, location.search]);

  const loginCallback = (data) => {
    if (data.workspaces.length > 0) {
      setClientSystemId(queryClientSystemId || data.workspaces[0].client_system_id);
      setLoginState('logged_in');
    } else {
      console.error('No workspaces found for the user');
      setLoginState('not_logged_in');
      window.location.href = '/';
    }
  }

  const loginFailureCallback = () => {
    window.location.href = '/';
    setLoginState('not_logged_in');
  }  

  useEffect(() => {
    if (loginState === null) {
      getLoginInfoFromCookie(loginCallback, loginFailureCallback);
    }
  }, [])

  if (!loginState) {
    return "";
  }

  if (!clientSystemId && loginState === 'not_logged_in'){
    return <LoginModule onSuccessCallback={loginCallback} />;
  }

  if (issues.length === 0) {
    return "";
  }

  return (
    <div className="d-flex">
      <Sidebar clientSystemId={queryClientSystemId} />
      <div className="container-fluid" style={{ 
        marginLeft: '64px',
        maxWidth: 'calc(100vw - 64px)',
        transition: 'margin-left 0.2s ease-in-out'
      }}>
        <div className="row">
          {/* Left panel - Issue List */}
          <div className="col-md-3 position-sticky" style={{ 
            top: '64px',
            height: 'calc(100vh - 64px)',
            overflowY: 'auto',
            paddingTop: '1rem'
          }}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-2 bg-light p-2">
                  <h5 className="mb-0">Investigations</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button 
                      variant="link" 
                      className="p-0 d-flex align-items-center justify-content-center" 
                      style={{ width: '32px', height: '32px' }}
                      onClick={() => toggleSearch()}
                    >
                      <IoSearch size={20} color="grey" />
                    </Button>
                  </div>
                </div>
                {showSearch && <div>
                  <InputGroup>
                    <InputGroup.Text><Search /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                </div>}
                <ListView items={getFilteredIssues()} selectedIssue={selectedIssue} selectHandler={handleIssueSelect}/>
              </div>
            </div>
          </div>
          
          {/* Right panel - Issue Details */}
          <div className="col-md-9" style={{ 
            paddingTop: '1rem',
            maxWidth: '100%',
            overflowX: 'hidden'
          }}>
            {issues[selectedIssue] !== undefined && (
              <DetailView key={`issue-${issues[selectedIssue].id}`} item={issues[selectedIssue]} selectIssueFromIdHandler={selectIssueFromId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ReportPage() {
  let { reportId } = useParams();
  return (
    <Container reportId={reportId} />
  );
}

export default ReportPage;
