export const getLoginInfoFromCookie = (successCallback, failureCallback) => {
  const cookie = document.cookie;
  const cookieArray = cookie.split(';');
  let token = null;
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring('token='.length);
      break;
    }
  }

  if (!token) {
    failureCallback();
    return;
  }

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
  fetch(`${API_BASE_URL}/api/userinfo`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .then((data) => {
    if (data && data.user) {
      successCallback(data);
    } else {
      failureCallback();
    }
  })
  .catch((error) => {
    console.error('Error:', error);
    failureCallback();
  });
}

export const getIdTokenFromCookie = () => {
  const cookieArray = document.cookie.split(';');
  let token = null;
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring('token='.length);
      break;
    }
  }
  return token;
};

// utils.js (continued)
export const makeAuthenticatedRequest = (endpoint, options = {}) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
  const idToken = getIdTokenFromCookie();

  if (!idToken) {
    console.error('No ID token found. User might not be authenticated.');
    return Promise.reject('No ID token found');
  }

  const headers = {
    'Authorization': `Bearer ${idToken}`,
    'Content-Type': 'application/json',
    ...options.headers,
  };

  return fetch(`${API_BASE_URL}${endpoint}`, {
    ...options,
    headers,
  })
    .then((response) => {
      if (response.status === 401) {
        console.error('Unauthorized. Token might be invalid or expired.');
        // Optionally handle token expiration here
        //throw new Error('Unauthorized');
        logout();
        return Promise.reject('Unauthorized');
      }
      return response;
    });
};



export const logout = () => {
  // Delete the gloginInfo cookie
  document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  
  // Redirect to the home page
  window.location.href = '/';
};



export const formatDateTime = (dateStr) => {
  if (!dateStr) return '';
  
  try {
    // Parse the date string
    //const date = new Date(dateStr.replace(' GMT', ''));
    const date = new Date(dateStr)
    
    // Check if date is valid
    if (isNaN(date.getTime())) {
      return dateStr; // Return original string if parsing fails
    }

    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).format(date);
  } catch (e) {
    console.error('Error formatting date:', e);
    return dateStr; // Return original string if any error occurs
  }
};
