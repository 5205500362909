import { useState, memo } from 'react';
import { Card, Collapse, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {IoAddCircleOutline, IoRemoveCircleOutline} from 'react-icons/io5'; // use plus minus icons
import {IoThumbsDownOutline, IoThumbsUpOutline, IoThumbsDown, IoThumbsUp} from 'react-icons/io5';


import MetricView from '../metrics';
import LogsView from '../logs';
import { ApmSpanView } from './apm_span_view';
import { WidgetView, ApmMetricsView } from './widget_view';

import '../css/issue_details.css';


export const CollapsibleCard = ({ 
    title, 
    children, 
    className = 'mb-3',
    defaultCollapsed = false,
    headerClassName = 'card-header-custom',
    bodyClassName = '',
    id = null
  }) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  
    return (
      <Card className={className} id={id}>
        <Card.Header className={`${headerClassName} d-flex align-items-center`}>
          <button 
            className="btn btn-link px-2" 
            onClick={() => setIsCollapsed(!isCollapsed)}
            style={{ color: '#5935c9', textDecoration: 'none' }}
          >
            {isCollapsed ? (
              <IoAddCircleOutline />
            ) : (
              <IoRemoveCircleOutline />
            )}
          </button>
          <strong>{title}</strong>
        </Card.Header>  
        <Collapse in={!isCollapsed}>
          <div>
            <Card.Body className={bodyClassName}>
              {children}
            </Card.Body>
          </div>
        </Collapse>
      </Card>
    );
};

export const IdentifierMapView = (props) => {
    if (!props.identifierMap || Object.keys(props.identifierMap).length === 0) {
        return null;
    }

    return (
        <div className="mt-2">
          <p className="mb-2 font-size-085">The following slices were identified as relevant.</p>
          <h6 className="mb-2 text-muted" style={{fontSize: '0.85rem'}}>Relevant Identifiers</h6>
          <pre 
            style={{ 
              backgroundColor: '#f8f9fa',
              padding: '1rem',
              borderRadius: '0.375rem',
              fontSize: '0.8rem',
              maxHeight: '200px',
              overflowY: 'auto'
            }}
          >
            {JSON.stringify(props.identifierMap, null, 2)}
          </pre>
        </div>
    );
};

export const SimilarIssues = ({ analysisData, selectIssueFromIdHandler }) => {
    return (
      <CollapsibleCard title="Previous Similar Issues">
        <div className="similar-issues-list">
          {analysisData.relatedIssueData.map((issue) => (
            <div 
              key={issue.id} 
              className="issue-item py-2"
              style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}
            >
              <button
                onClick={() => selectIssueFromIdHandler(issue.id)}
                className="issue-summary text-start btn btn-link p-0"
                style={{
                  maxWidth: '97%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textDecoration: 'none',
                  color: '#2c3e50',
                  fontSize: '0.95rem',
                }}
                onMouseOver={(e) => e.target.style.color = '#5935c9'}
                onMouseOut={(e) => e.target.style.color = '#2c3e50'}
              >
                {issue.summary}
              </button>
            </div>
          ))}
        </div>
      </CollapsibleCard>
    );
  };


export const ReadableRootCause = ({ readableRCA, referenceClickHandler }) => {
    return (
      <div className="readable-root-cause">
        {readableRCA && readableRCA.sections && readableRCA.sections.length > 0 && (
          <div className="d-flex flex-column gap-3">
            {readableRCA.sections.map((section, index) => {
              if (typeof section.text !== 'string') return null;
              
              return (
                <div 
                  key={index} 
                  className="insight-container"
                  style={{
                    position: 'relative',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                  }}
                >
                  <div className="d-flex justify-content-between align-items-start gap-3">
                    <div className="insight-text">
                      {section.text}
                    </div>
                    {section.references && Array.isArray(section.references) && (
                      <div className="insight-references">
                        {section.references.map((ref, refIndex) => (
                          <button
                            key={refIndex}
                            onClick={() => referenceClickHandler(ref)}
                            className="badge bg-light text-primary border border-primary-subtle mx-1 px-2 py-1"
                            onMouseOver={(e) => e.target.style.backgroundColor = '#e8f0fe'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#fff'}
                          >
                            #{ref}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="insight-accent-line" />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };


export const getStepTitle = (step, index) => {
    if (step['step_type'] === 'ongoing') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span className="highlight-ongoing">Currently running</span>: {step['currentNextStep']}
        </>
      );
    } else if (step['step_type'] === 'user_input') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span className="highlight-logs">User Suggestion</span>
          <code className="service-name">{step['message']}</code>
        </>
      );
    } else if (step['step_type'] === 'log_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzed
          {' '}
          <span className="highlight-logs">logs</span>
          {' '}
          from service
          {' '}
          <code className="service-name" style={{ maxWidth: '450px' }}>
            {step?.file_analyzed || step?.params?.service || step?.params?.dataset || ''}
          </code>
        </>
      );
    } else if (step['step_type'] === 'metrics_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzed
          {' '}
          <span className="highlight-logs">metric</span>
          {' '}
          <code className="service-name" style={{ maxWidth: '400px' }}>{step['metric_analyzed']}</code>
          {' '}
          from service
          {' '}
          <code className="service-name" style={{ maxWidth: '450px' }}>{step['service_analyzed']}</code>
        </>
      );
    } else if (step['step_type'] === 'widget_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzed
          {' '}
          <span className="highlight-logs">widget</span>
          {' '}
          <code className="service-name" style={{ maxWidth: '300px' }}>{step['params']['widget_title']}</code>
          {' '}
          from Dashboard
          {' '}
          <code className="service-name" style={{ maxWidth: '450px' }}>{step['params']['dashboard_title']}</code>
        </>
      );
    } else if (step['step_type'] === 'apm_span_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span>Exemplar</span>
          {' '}
          <span className="highlight-logs">trace</span>
          {' '}
          <span>analysis</span>
        </>
      );
    } else if (step['step_type'] === 'apm_metrics_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzed
          {' '}
          <span className="highlight-logs">metric</span>
          {' '}
          <code className="service-name" style={{ maxWidth: '400px' }}>{step['metric_name']}</code>
        </>
      );
    }
  };

  export const build_log_query = (log_source_info) => {
    if (!log_source_info) {
      return null;
    }
    if (log_source_info.log_source_type === 'gcp'){
      let u = 'https://console.cloud.google.com/logs/query;query=' + encodeURIComponent(log_source_info.gcp.query);
      return {
        url: u.replace('(', '%2528').replace(')', '%2529'),
        dataSource: 'GCP',
        rawQuery: log_source_info.gcp.query
      };
    } else if (log_source_info.log_source_type === 'grafana'){
      let url = new URL(log_source_info.grafana.query);
      let rawQuery = url.searchParams.get('panes');
      return {
        url: log_source_info.grafana.query,
        dataSource: 'Grafana',
        rawQuery: rawQuery
      };
    } else if (log_source_info.log_source_type === 'aws'){
      return {
        url: null,
        rawQuery: log_source_info.aws.query,
        dataSource: 'AWS',
        startTime: log_source_info.start_time,
        endTime: log_source_info.end_time
      }
    } else if (log_source_info.log_source_type === 'datadog'){
      return {
        url: null,
        rawQuery: log_source_info.datadog.query,
        dataSource: 'Datadog',
        startTime: log_source_info.start_time,
        endTime: log_source_info.end_time
      }
    } else if (log_source_info.log_source_type === 'observeinc'){
      return {
        url: null,
        rawQuery: log_source_info.observeinc.query,
        dataSource: 'ObserveInc',
        startTime: log_source_info.start_time,
        endTime: log_source_info.end_time
      }
    }
    return null;
  };

const getDataSource = (analysisStepData) => {
  if ('gcp' in analysisStepData['data_source_info']){
    return 'GCP';
  } else if ('aws' in analysisStepData['data_source_info']){
    return 'AWS';
  }
  return '';
}

export const build_metric_query = (analysisStepData) => {
  if ('gcp' in analysisStepData['data_source_info'] && 'query' in analysisStepData['data_source_info']['gcp']) {
    return {
      url: 'https://console.cloud.google.com/monitoring/metrics-explorer?pageState=' + encodeURIComponent(JSON.stringify(
        {"timeSeriesQuery": analysisStepData['data_source_info']['gcp']['query']})),
      rawQuery: analysisStepData['data_source_info']['gcp']['query'],
      dataSource: 'gcp'
    };
  }
  if ('datadog' in analysisStepData['data_source_info'] && 'query' in analysisStepData['data_source_info']['datadog']) {
    return {
      url: null,
      rawQuery: analysisStepData['data_source_info']['datadog']['query'],
      dataSource: 'datadog'
    };
  }
  let query = {
    'metric': analysisStepData['metric_analyzed'],
  };
  if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['attributes']){
    query['attributes'] = analysisStepData['data_source_info']['metric']['attributes'];
  }
  if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['start_time']){
    query['start_time'] = analysisStepData['data_source_info']['metric']['start_time'];
  }
  if (analysisStepData['data_source_info'] && analysisStepData['data_source_info']['metric'] && analysisStepData['data_source_info']['metric']['end_time']){
    query['end_time'] = analysisStepData['data_source_info']['metric']['end_time'];
  }
  return {
    url: null,
    rawQuery: JSON.stringify(query, null, 4),
    dataSource: getDataSource(analysisStepData)
  };
};

export const createDisplayStepFromLogAnalysisData = (analysisStepData, displayNextStep, displayNextStepReason, params={}) => {
    var rootCauseObservationIndx = -1;
    if (!analysisStepData["observations"] || analysisStepData["observations"].length === 0) {
      return {
        'step_type': 'log_analysis',
        'step': displayNextStep,
        'reason': displayNextStepReason,
        'file_analyzed': analysisStepData["service_analyzed"],
        'params': params,
        'summary': analysisStepData["summary"] || "No relevant logs found.",
        'query': build_log_query(analysisStepData["log_source_info"]),
        'observation': {
          'logLines': analysisStepData["log_source_info"]["logs"] ? analysisStepData["log_source_info"]["logs"] : [],
          'highlight': null,
        }
      };
    }
    for (var observationIndx=0; observationIndx < analysisStepData["observations"].length; observationIndx++) {
      let observation = analysisStepData["observations"][observationIndx];
      if (observation["is_root_cause"]) {
        rootCauseObservationIndx = observationIndx;
        break;
      }
    }

    if (rootCauseObservationIndx === -1) {
      for (var observationIndx=0; observationIndx < analysisStepData["observations"].length; observationIndx++) {
        let observation = analysisStepData["observations"][observationIndx];
        if (observation["relevance_to_incident"] && observation["relevance_to_incident"].toLowerCase().includes("high")) {
          rootCauseObservationIndx = observationIndx;
          break;
        }
      }
    }

    // If no high relevance observation, show the first one
    if (rootCauseObservationIndx === -1) {
      rootCauseObservationIndx = 0;
    }

    let logObservation = analysisStepData["observations"][rootCauseObservationIndx]["log_message"];
    let logObservationRelevance = analysisStepData["observations"][rootCauseObservationIndx]["relevance_to_incident"];
    return {
        'step_type': 'log_analysis',
        'step': displayNextStep,
        'reason': displayNextStepReason,
        'file_analyzed': analysisStepData["service_analyzed"],
        'params': params,
        'summary': analysisStepData["summary"],
        'details': analysisStepData["details"],
        'query': build_log_query(analysisStepData["log_source_info"]),
        'observation': {
          'logLines': analysisStepData["log_source_info"]["logs"],
          'highlight': logObservation,
          'relevance': logObservationRelevance
        }
    };
  };


export const createDisplayStepFromWidgetAnalysisData = (analysisStepData, displayNextStep, displayNextStepReason, params) => {
    let ret = {
      'step_type': 'widget_analysis',
      'step': displayNextStep,
      'reason': displayNextStepReason,
      'widget_data': analysisStepData['widget_data'],
      'summary': analysisStepData['summary'],
      'details': analysisStepData['details'],
      'identifiers': analysisStepData['identifiers'],
      'observation_type': 'widget_analysis',
      'params': params
    };
    return ret;
};

export const createDisplayStepFromApmSpanAnalysisData = (analysisStepData) => {
    let ret = {
      'step_type': 'apm_span_analysis',
      'span_tree': analysisStepData['span_tree'],
      'summary': analysisStepData['summary'],
      'query': analysisStepData['query'],
      'exemplar_trace_id': analysisStepData['exemplar_trace_id'],
      'observation_type': analysisStepData['observation_type'],
    };
    return ret;
};

export const createDisplayStepFromApmMetricsAnalysisData = (analysisStepData) => {
    let ret = {
      'step_type': 'apm_metrics_analysis',
      'timeseries_data': analysisStepData['timeseries_data'],
      'summary': analysisStepData['summary'],
      'observation_type': analysisStepData['observation_type'],
      'metric_name': analysisStepData['metric_name'],
      'query': analysisStepData['query'],
    };
    return ret;
};

export const createDisplayStepFromMetricsAnalysisData = (analysisStepData, displayNextStep, displayNextStepReason, params={}) => {
    let ret = {
      'step_type': 'metrics_analysis',
      'step': displayNextStep,
      'reason': displayNextStepReason,
      'service_analyzed': analysisStepData["service_analyzed"],
      'metric_analyzed': analysisStepData["metric_analyzed"],
      'summary': analysisStepData["summary"],
      'query': build_metric_query(analysisStepData),
      'params': params,
      'metric': analysisStepData['data_source_info']['metric']
    };
    return ret;
};

export const LogAnalysisStepView = memo(function LogAnalysisStepView(props) {
    let stepTitleComponents = {
      'action': 'logs',
      'service': props.analysisStepData['file_analyzed'],
      'stepIndx': props.stepIndx,
    };
    return (
          <LogsView 
              logData={props.analysisStepData.observation.logLines} 
              highlight={props.analysisStepData.observation.highlight} 
              stepTitleComponents={stepTitleComponents}
              stepReason={props.analysisStepData.reason} 
              query={props.analysisStepData.query} 
              stepPostscript={props.analysisStepData.observation.relevance}
              params={props.analysisStepData.params}
              summary={props.analysisStepData.summary}
              details={props.analysisStepData.details}
          />
    );
  }, (prevProps, nextProps) => {
    return (
      prevProps.analysisStepData.stepId === nextProps.analysisStepData.stepId
    );
});
  
  
export  const MetricAnalysisStepView = (props) => {
    let metricData = props.analysisStepData.metric ? [props.analysisStepData.metric] : [];
    let stepTitleComponents = {
      'action': 'metrics',
      'metric': props.analysisStepData['metric_analyzed'],
      'service': props.analysisStepData['service_analyzed'],
      'stepIndx': props.stepIndx,
    };
  
    return (
          <MetricView 
              metricData={metricData} 
              stepTitleComponents={stepTitleComponents}
              stepReason={props.analysisStepData.reason} 
              stepPostscript={props.analysisStepData.summary} 
              query={props.analysisStepData.query} 
          />
    );
  };

export const ApmSpanAnalysisStepView = memo(function ApmSpanAnalysisStepView(props) {
  return (
    <div className="my-4">
      { // Analysis section
        <>
          <h6 className="mb-2 text-muted font-size-085">Analysis</h6>
          <p className="mb-3 font-size-085">
            <span>{props.analysisStepData.summary}</span>
          </p>
        </>
      }
    
      {
        // Data section
        props.analysisStepData.span_tree && (
          <>
            <ApmSpanView spanTree={props.analysisStepData.span_tree} query={props.analysisStepData.query}/>
          </>
        )
      }
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.analysisStepData.stepId === nextProps.analysisStepData.stepId
  );
});

export const ApmMetricsAnalysisStepView = memo(function ApmMetricsAnalysisStepView(props) {
  return (
    <div className="my-4">
      { // Analysis section
        <>
          <h6 className="mb-2 text-muted font-size-085">Analysis</h6>
          <p className="mb-3 font-size-085">
            <span>{props.analysisStepData.summary}</span>
          </p>
        </>
      }
    
      {
        // Data section
        props.analysisStepData.timeseries_data && (
          <>
            <ApmMetricsView tsData={props.analysisStepData.timeseries_data} query={props.analysisStepData.query}/>
          </>
        )
      }
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.analysisStepData.stepId === nextProps.analysisStepData.stepId
  );
});

export const WidgetAnalysisStepView = memo(function WidgetAnalysisStepView(props) {
  const [analysisDetailsExpanded, setAnalysisDetailsExpanded] = useState(false);
  return (
    <div className="my-4">
      <h6 className="mb-2 text-muted font-size-085">Context</h6>
      { // Context section
        <>
          {props.analysisStepData.reason && (
            <p className="mb-3 text-muted font-size-085">{props.analysisStepData.reason}</p>
          )}
        </>
      }

      { // Metric names section
        false && props.analysisStepData?.params?.widget_metric_names && (
          <>
            <p className="text-muted mb-2 font-size-085">Metric Names</p>
            <pre className="bg-light p-3 rounded" style={{ color: '#333' }}>{props.analysisStepData.params.widget_metric_names.join(', ')}</pre>
          </>
        )
      }

      { // Analysis section
        <>
          <h6 className="mb-2 text-muted font-size-085">Analysis</h6>
          <p className="mb-3 font-size-085">
            <span>{props.analysisStepData.summary}</span>
            {!analysisDetailsExpanded && <span onClick={() => setAnalysisDetailsExpanded(true)} className="text-primary font-size-085" style={{ cursor: 'pointer' }}> Show details</span>}
          </p>
          {analysisDetailsExpanded && <>
            <p className="mb-3 font-size-085">
              <span>{props.analysisStepData.details}</span>
              <span onClick={() => setAnalysisDetailsExpanded(false)} className="text-primary font-size-085" style={{ cursor: 'pointer' }}> Hide details</span>
            </p>
          </>}
        </>
      }
    
      {
        // Identifiers section
        <IdentifierMapView identifierMap={props.analysisStepData.identifiers} />
      }

      {
        // Data section
        props.analysisStepData.widget_data && (
          <>
            <WidgetView widgetData={props.analysisStepData.widget_data} />
          </>
        )
      }
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.analysisStepData.stepId === nextProps.analysisStepData.stepId
  );
});

export const FeedbackButtons = ({ feedbackState, handleFeedback }) => {
  const feedbackOptions = [
    { tooltip: "Good response", icon: IoThumbsUp, outlineIcon: IoThumbsUpOutline, value: 1 },
    { tooltip: "Bad response", icon: IoThumbsDown, outlineIcon: IoThumbsDownOutline, value: -1 },
  ];

  return (
    <div className="mt-3 d-flex justify-content-end">
      {feedbackOptions.map(({ tooltip, icon: Icon, outlineIcon: OutlineIcon, value }) => (
        <OverlayTrigger
          key={value}
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{tooltip}</Tooltip>}
        >
          <Button
            variant="outline-dark"
            size="sm"
            className={value === 1 ? "me-1" : ""}
            onClick={() => handleFeedback(value)}
          >
            {feedbackState === value ? <Icon /> : <OutlineIcon />}
          </Button>
        </OverlayTrigger>
      ))}
    </div>
  );
};


export const EventAnalysisDataView = ({ events }) => {
  const [expandedEvents, setExpandedEvents] = useState({});

  // Filter out duplicate events by keeping only the first occurrence of each ID
  const uniqueEvents = events?.reduce((acc, current) => {
    if (!acc.find(event => event.id === current.id)) {
      acc.push(current);
    }
    return acc;
  }, []);

  const toggleEvent = (id) => {
    setExpandedEvents(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  if (!uniqueEvents || uniqueEvents.length === 0) {
    return null;
  }

  return (
    <div className="event-analysis-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <h6 className="mb-2 text-muted" style={{fontSize: '0.85rem'}}>Relevant Events</h6>
      <div className="list-group">
        {uniqueEvents.map((event) => (
          <div 
            key={event.id}
            className="py-2"
            style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}
          >
            <div 
              className="d-flex justify-content-between align-items-start"
              onClick={() => toggleEvent(event.id)}
              style={{ 
                cursor: 'pointer',
                transition: 'color 0.2s ease'
              }}
              onMouseOver={(e) => e.currentTarget.style.color = '#5935c9'}
              onMouseOut={(e) => e.currentTarget.style.color = ''}
            >
              <div className="me-3 flex-grow-1">
                <div className="d-flex align-items-center gap-2">
                  <span className="text-muted font-size-085">
                    {formatTimestamp(event.timestamp)}
                  </span>
                  <span className="font-size-085">{event.title}</span>
                </div>
              </div>
              <span 
                className="text-muted"
                style={{ 
                  transform: expandedEvents[event.id] ? 'rotate(-180deg)' : 'rotate(0)',
                  transition: 'transform 0.2s ease'
                }}
              >
                ▼
              </span>
            </div>

            {expandedEvents[event.id] && (
              <div className="mt-3 ms-2">
                {event.message && (
                  <div className="mb-3">
                    <pre 
                      style={{ 
                        backgroundColor: '#f8f9fa',
                        padding: '1rem',
                        borderRadius: '0.375rem',
                        fontSize: '0.75rem',
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                      {event.message}
                    </pre>
                  </div>
                )}

                {event.tags && event.tags.length > 0 && (
                  <div>
                    <h6 className="text-muted mb-2 font-size-085">Tags</h6>
                    <div className="d-flex flex-wrap gap-2">
                      {event.tags.map((tag, index) => (
                        <span 
                          key={index}
                          className="badge bg-light text-dark border font-size-085"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const RunbookAnalysisView = ({ findings }) => {
  if (!findings?.sections || findings.sections.length === 0) {
    return null;
  }

  const getRunbookIcon = (runbookType) => {
    switch (runbookType) {
      case 'datadog_notebook':
        return '📓';
      case 'confluence_page':
        return '📄';
      default:
        return '📑';
    }
  };

  return (
    <div className="runbook-analysis-container">
      <p>The following documents may be relevant</p>
      <div className="list-group">
        {findings.sections.map((section, index) => (
          <div 
            key={index}
            className="list-group-item border-0 px-0"
            style={{ 
              borderBottom: '1px solid rgba(0,0,0,0.05)',
              backgroundColor: 'transparent' 
            }}
          >
            <div className="d-flex gap-2 align-items-start">
              <span style={{fontSize: '1.1rem'}}>
                {getRunbookIcon(section.runbook_type)}
              </span>
              <div className="flex-grow-1">
                <a
                  href={section.runbook_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-2 d-block"
                  style={{
                    color: '#5935c9',
                    textDecoration: 'none',
                    fontSize: '0.9rem',
                    fontWeight: '500'
                  }}
                  onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                  onMouseOut={(e) => e.target.style.textDecoration = 'none'}
                >
                  {section.runbook_title}
                </a>
                <p className="mb-0 font-size-085" style={{color: '#2c3e50'}}>
                  {section.relevance}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
