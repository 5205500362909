import { useEffect, useState } from 'react'

export const GithubCommitInfo = ({ commitInfo, showConclusion }) => {
  let [relevantChanges, setRelevantChanges] = useState([]);

  useEffect(() => {
    if (!commitInfo || !commitInfo.relevant_changes) {
      return;
    }

    let changes = commitInfo.relevant_changes.map((change) => {
      let patch = change.patch_diff || '';
      let lines = patch.split('\n');
      let diffData = lines.map(line => {
        if (line.startsWith('-')) {
          return {
            type: 'delete',
            content: line.substring(1),
            lineNumbers: ['1', null] // You might want to implement proper line numbering
          };
        } else if (line.startsWith('+')) {
          return {
            type: 'add',
            content: line.substring(1),
            lineNumbers: [null, '1'] // You might want to implement proper line numbering
          };
        } else {
          return {
            type: 'context',
            content: line,
            lineNumbers: ['1', '1'] // You might want to implement proper line numbering
          };
        }
      });

      return {
        file_name: change.file_name,
        diffData: diffData,
        commit_url: 'https://github.com/' + change.repo + '/commit/' + change['commit_sha'],
      };
    });

    let changesByCommitUrl = new Map();
    for (let change of changes) {
      if (!changesByCommitUrl.has(change.commit_url)) {
        changesByCommitUrl.set(change.commit_url, []);
      }
      changesByCommitUrl.get(change.commit_url).push(change);
    }

    changes = [];
    for (let [commitUrl, commitChanges] of changesByCommitUrl) {
      changes.push({
        commit_url: commitUrl,
        changes: commitChanges,
      });
    }

    setRelevantChanges(changes);
  }, [commitInfo]);

  if (relevantChanges.length === 0) {
    return null;
  }

  return (
    <div className="github-commit-info mt-3">
      {showConclusion && <div className="insight-container mb-3"
        style={{
          position: 'relative',
          backgroundColor: '#f8f9fa',
          borderRadius: '8px',
          padding: '12px 16px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
        }}
      >
        <div className="d-flex justify-content-between align-items-start">
          <span className="insight-text">{commitInfo.conclusion}</span>
        </div>
        <div className="insight-accent-line" />
      </div>}

      <div className="mx-3">
        {relevantChanges.map((changeData, index) => (
          <div className="mb-3" key={index}>
            {changeData.changes.map((change, changeIndex) => (
              <div key={changeIndex} className="card mb-3">
                <div className="card-header bg-light py-2 px-3 d-flex justify-content-between align-items-center">
                  <code className="text-muted">{change.file_name}</code>
                  <a 
                    href={changeData.commit_url} 
                    target="_blank" 
                    rel="noreferrer"
                    className="badge bg-light text-primary border border-primary-subtle ms-2 px-2 py-1"
                    style={{ 
                      textDecoration: 'none',
                      fontSize: '0.85rem',
                      transition: 'all 0.2s ease',
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#e8f0fe'}
                    onMouseOut={(e) => e.target.style.backgroundColor = '#fff'}
                  >
                    View Commit
                  </a>
                </div>
                <div className="card-body p-0">
                  <div className="diff-viewer">
                    {change.diffData.map((line, lineIndex) => (
                      <div
                        key={lineIndex}
                        className={`d-flex diff-line ${
                          line.type === 'add' ? 'diff-line-add' :
                          line.type === 'delete' ? 'diff-line-delete' : ''
                        }`}
                      >
                        {false && <div className="d-flex border-end text-muted bg-light user-select-none">
                          <div className="px-2 text-end border-end" style={{width: '4em'}}>
                            {line.lineNumbers[0] || ' '}
                          </div>
                          <div className="px-2 text-end" style={{width: '4em'}}>
                            {line.lineNumbers[1] || ' '}
                          </div>
                        </div>}
                        <div className="flex-grow-1 px-2" style={{whiteSpace: 'pre'}}>
                          <span className="d-inline-block" style={{width: '1em'}}>
                            {line.type === 'add' && <span className="diff-add">+</span>}
                            {line.type === 'delete' && <span className="diff-delete">-</span>}
                          </span>
                          {line.content}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

