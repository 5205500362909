import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Form, Modal, Dropdown } from 'react-bootstrap';
import withAuth from '../../components/withAuth';
import { makeAuthenticatedRequest } from '../utils';

function AdminPage() {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [showAutoMonitorModal, setShowAutoMonitorModal] = useState(false);
  const [modalInputValue, setModalInputValue] = useState('');
  const [showSlackDeletionModal, setShowSlackDeletionModal] = useState(false);
  const [showShadowCreationModal, setShowShadowCreationModal] = useState(false);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await makeAuthenticatedRequest('/api/admin/organizations');
      if (response.ok) {
        const data = await response.json();
        const sortedOrgs = data.organizations.sort((a, b) => a.id - b.id);
        setOrganizations(sortedOrgs);
      } else {
        throw new Error('Failed to fetch organizations');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleIncreaseCredits = async (orgId, amount) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/admin/organizations/${orgId}/increase_credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount }),
      });

      if (response.ok) {
        // Refresh the organizations list
        await fetchOrganizations();
      } else {
        throw new Error('Failed to increase credits');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSetAutoMonitor = async (orgId, autoMonitorEnabled) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/admin/organizations/${orgId}/set_auto_monitor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ autoMonitorEnabled: autoMonitorEnabled === '1'}),
      });

      if (response.ok) {
        // Refresh the organizations list
        await fetchOrganizations();
      } else {
        throw new Error('Failed to set auto monitor');
      }
    } catch (err) {
      setError(err.message);
    }
  }

  const handleSetTrialExpiryDate = async (orgId, expiryDate) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/admin/organizations/${orgId}/set_trial_end_date`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ expiryDate }),
      });

      if (response.ok) {
        // Refresh the organizations list
        await fetchOrganizations();
      } else {
        throw new Error('Failed to set trial expirt date');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleModalSubmit = async () => {
    if (showCreditsModal) {
      await handleIncreaseCredits(selectedOrg.id, modalInputValue);
    } else if (showTrialModal) {
      await handleSetTrialExpiryDate(selectedOrg.id, modalInputValue);
    } else if (showAutoMonitorModal) {
      await handleSetAutoMonitor(selectedOrg.id, modalInputValue);
    }
    setModalInputValue('');
    setShowCreditsModal(false);
    setShowTrialModal(false);
    setShowAutoMonitorModal(false);
  };

  const handleOrgClick = (e, workspace, type) => {
    e.preventDefault();
    const baseUrl = `${window.location.origin}/${type}?client_system_id=${workspace.client_system_id}`;
    window.open(baseUrl, '_blank');
  };

  const handleDeleteSlackData = async (orgId) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/admin/organizations/${orgId}/delete_slack_data`, {
        method: 'POST',
      });

      if (response.ok) {
        // Refresh the organizations list
        await fetchOrganizations();
      } else {
        throw new Error('Failed to delete Slack data');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleShadowCreation = async (orgId) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/admin/organizations/${orgId}/create_shadow`, {
        method: 'POST',
      });

      if (response.ok) {
        // Refresh the organizations list
        await fetchOrganizations();
      } else {
        throw new Error('Failed to create shadow');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className="mt-4">
      <h1>Admin Dashboard</h1>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Organization ID</th>
            <th>Name</th>
            <th>Owner</th>
            <th>Current Credits</th>
            <th>Auto Monitor</th>
            <th>Trial Status</th>
            <th>Users</th>
            <th>Workspaces</th>
            <th style={{ width: '120px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map((org) => (
            <tr key={org.id}>
              <td>{org.id}</td>
              <td>
                {org.workspaces.length > 0 ? (
                  <div className="d-flex align-items-center gap-2">
                    <a
                      href="#"
                      onClick={(e) => handleOrgClick(e, org.workspaces[0], 'monitor')}
                      className="text-primary text-decoration-none"
                      style={{ cursor: 'pointer' }}
                    >
                      {org.name}
                    </a>
                    <a
                      href="#"
                      onClick={(e) => handleOrgClick(e, org.workspaces[0], 'account')}
                      className="text-secondary text-decoration-none small"
                      style={{ cursor: 'pointer' }}
                    >
                      (config)
                    </a>
                  </div>
                ) : (
                  org.name
                )}
              </td>
              <td>{org.owner}</td>
              <td>{org.credits}</td>
              <td>
                <span className={`badge bg-${org.auto_monitor_enabled ? 'success' : 'secondary'}`}>
                  {org.auto_monitor_enabled ? 'Enabled' : 'Disabled'}
                </span>
              </td>
              <td>
                {org.in_trial ? (
                  <div>
                    <span className="badge bg-warning">Trial</span>
                    {org.trial_end_date && (
                      <small className="d-block text-muted">
                        Expires: {new Date(org.trial_end_date).toLocaleDateString()}
                      </small>
                    )}
                  </div>
                ) : (
                  <span className="badge bg-success">Active</span>
                )}
              </td>
              <td>{org.users.join(', ')}</td>
              <td>{org.workspaces.map(workspace => workspace.name).join(', ')}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary" size="sm">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                      setSelectedOrg(org);
                      setShowCreditsModal(true);
                    }}>Increase Credits</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      setSelectedOrg(org);
                      setShowTrialModal(true);
                    }}>Set Trial Expiry</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      setSelectedOrg(org);
                      setShowAutoMonitorModal(true);
                    }}>Set Auto Monitor</Dropdown.Item>
                    <Dropdown.Item 
                      onClick={() => {
                        setSelectedOrg(org);
                        setShowShadowCreationModal(true);
                      }}
                    >Create Shadow Org</Dropdown.Item>
                    <Dropdown.Item 
                      onClick={() => {
                        setSelectedOrg(org);
                        setShowSlackDeletionModal(true);
                      }}
                      className="text-danger"
                    >Delete Slack Data</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Credits Modal */}
      <Modal show={showCreditsModal} onHide={() => setShowCreditsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Increase Credits for {selectedOrg?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Amount to increase</Form.Label>
            <Form.Control
              type="number"
              value={modalInputValue}
              onChange={(e) => setModalInputValue(e.target.value)}
              min="1"
              placeholder="Enter amount"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreditsModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Increase Credits
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Trial Expiry Modal */}
      <Modal show={showTrialModal} onHide={() => setShowTrialModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Set Trial Expiry for {selectedOrg?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Trial Expiry Date</Form.Label>
            <Form.Control
              type="date"
              value={modalInputValue}
              onChange={(e) => setModalInputValue(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTrialModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Set Trial Expiry
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Auto Monitor Modal */}
      <Modal show={showAutoMonitorModal} onHide={() => setShowAutoMonitorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Set Auto Monitor for {selectedOrg?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Auto Monitor Status</Form.Label>
            <Form.Select
              value={modalInputValue}
              onChange={(e) => setModalInputValue(e.target.value)}
            >
              <option value="">Select status</option>
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAutoMonitorModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Set Auto Monitor
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Slack Deletion Modal */}
      <Modal show={showSlackDeletionModal} onHide={() => setShowSlackDeletionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Slack Data for {selectedOrg?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete all Slack data for this organization? This will:</p>
          <ul>
            <li>Remove all Slack installations</li>
            <li>Remove all alert subscriptions</li>
            <li>Require users to reconnect Slack if they want to use it again</li>
          </ul>
          <p className="text-danger">This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSlackDeletionModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={async () => {
              await handleDeleteSlackData(selectedOrg.id);
              setShowSlackDeletionModal(false);
            }}
          >
            Delete Slack Data
          </Button>
        </Modal.Footer>
      </Modal>
      {/* shadow creation modal */}
      <Modal show={showShadowCreationModal} onHide={() => setShowShadowCreationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create shadow org for {selectedOrg?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowShadowCreationModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={async () => {
              await handleShadowCreation(selectedOrg.id);
              setShowShadowCreationModal(false);
            }}
          >
            Create Shadow
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default withAuth(AdminPage);
