import { useState, useEffect, useMemo, createRef } from 'react';
import { Button, Spinner, Card, Accordion } from 'react-bootstrap';
import { SimilarIssues, ReadableRootCause, CollapsibleCard, IdentifierMapView, getStepTitle } from './common';
import {
  MetricAnalysisStepView, LogAnalysisStepView, WidgetAnalysisStepView, FeedbackButtons, EventAnalysisDataView,
  ApmSpanAnalysisStepView, ApmMetricsAnalysisStepView, RunbookAnalysisView
} from './common';
import {makeAuthenticatedRequest} from '../../routes/utils';
import {GithubCommitInfo} from './code_commit_view';

import '../demo.css';


export const moduleNameToTitle = (moduleName) => {
  if (moduleName === 'alert_frequency_agent'){
    return 'Alert Frequency Analysis';
  } else if (moduleName === 'log_analysis_agent'){
    return 'Log Analysis';
  } else if (moduleName === 'dashboard_analysis_agent') {
    return 'Dashboard Analysis';
    //} else if (moduleName === 'alert_metadata_extractor_agent') {
    //return 'Monitor Analysis';
  } else if (moduleName === 'code_commit_analysis_agent') {
    return 'Code Analysis';
  } else if (moduleName === 'event_analysis_agent') {
    return 'Event Analysis';
  } else if (moduleName === 'runbook_analysis_agent') {
    return 'Relevant Documents';
  } else if (moduleName === 'user_input') {
    return 'User Input';
  } else if (moduleName === 'apm_analysis_agent') {
    return 'APM Analysis';
  } else {
    return '';
  }
};

const AnalysisSteps = (props) => {
  const scrollRef = useMemo(() => createRef(), []);
  const [openKeys, setOpenKeys] = useState([]);

  const handleSelect = (newActiveKeys) => {
    setOpenKeys(newActiveKeys);
  };

  return (
    props.displaySteps && props.displaySteps.length > 0 && (
      <>
        <h6 className="mb-2 mt-2 text-muted" style={{fontSize: '0.9rem'}}>Step-by-Step Root Cause Analysis</h6>
        <Accordion alwaysOpen onSelect={handleSelect}>
          {props.displaySteps.map((step, index) => (
            <Accordion.Item key={index} eventKey={index.toString()} id={'step-' + (index+1)}>
              <Accordion.Header>
                {<div className="font-size-085">{getStepTitle(step, index)}</div>}
              </Accordion.Header>
              <Accordion.Body>
                {openKeys.includes(index.toString()) && (
                  <>
                    {step['step_type'] === 'log_analysis' && <LogAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                    {step['step_type'] === 'widget_analysis' && <WidgetAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                    {step['step_type'] === 'apm_span_analysis' && <ApmSpanAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                    {step['step_type'] === 'apm_metrics_analysis' && <ApmMetricsAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </>
    )
  );
};

export const ModuleAnalysisDataView = ({ moduleName, moduleData }) => {
  if (moduleName === 'code_commit_analysis_agent') {
    return <GithubCommitInfo commitInfo={moduleData} showConclusion={false} />
  } else if (moduleName === 'event_analysis_agent') {
    return <EventAnalysisDataView events={moduleData?.relevant_events} />
  } else if (moduleName === 'runbook_analysis_agent') {
    return <RunbookAnalysisView findings={moduleData} />
  }

  return '';
};

export const ModuleAnalysisView = (props) => {
  // Skip if module has no data to display
  if (!props.moduleOutput?.analysis_data?.rca &&
      !props.moduleOutput?.analysis_data?.user_input &&
      (!props.moduleOutput?.analysis_data?.identifier_updates || Object.keys(props.moduleOutput?.analysis_data?.identifier_updates).length === 0) &&
      !props.moduleOutput?.displaySteps) {
    return null;
  }

  const moduleTitle = moduleNameToTitle(props.moduleOutput['module_name']);
  if (!moduleTitle) return null;
  const moduleName = props.moduleOutput['module_name']

  if (moduleName === 'user_input') {
    return (
      <CollapsibleCard title={moduleTitle} bodyClassName='font-size-085'>
        {props.moduleOutput?.analysis_data?.user_input}
      </CollapsibleCard>
    );
  }

  const rcaSections = props.moduleOutput?.analysis_data?.rca?.split('\n').map((section, index) => (
    <div key={index}>
      <div 
        className="insight-container"
        style={{
          position: 'relative',
          backgroundColor: '#f8f9fa',
          borderRadius: '8px',
          padding: '12px 16px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
        }}
      >
        <div className="d-flex justify-content-between align-items-start gap-3">
          <div className="insight-text">
            {section}
          </div>
        </div>
        <div className="insight-accent-line" />
      </div>
    </div>
  ));

  return (
    <CollapsibleCard id={`module-${props.moduleOutput['id']}`} title={moduleTitle} bodyClassName="font-size-085" defaultCollapsed={true}>
      {moduleName !== 'runbook_analysis_agent' && rcaSections}
      {props.moduleOutput?.analysis_data?.identifier_updates && (
        <IdentifierMapView identifierMap={props.moduleOutput['analysis_data']['identifier_updates']} />
      )}
      {props.moduleOutput?.analysis_data?.data && (<ModuleAnalysisDataView moduleName={props.moduleOutput['module_name']} moduleData={props.moduleOutput['analysis_data']['data']} />)}
      <AnalysisSteps displaySteps={props.moduleOutput?.displaySteps} />
    </CollapsibleCard>
  );
};


export const RCASection = ({ analysisData, issueId, scrollToModuleIndx }) => {
  const [feedbackState, setFeedbackState] = useState(null);

  useEffect(() => {
    if (analysisData?.feedbackScore === 1) {
      setFeedbackState(1);
    } else if (analysisData?.feedbackScore === -1) {
      setFeedbackState(-1);
    } else {
      setFeedbackState(null);
    }
  }, [analysisData?.feedbackScore]);

  const handleFeedback = async (score) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/dashboard/v2/issues/${issueId}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback_score: score }),
      });

      if (response.ok) {
        setFeedbackState(score);
      } else {
        console.error("Failed to submit feedback");
        console.log(response);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const getCardContent = () => {
    if (!analysisData.combinedResponse) {
      return {
        title: "Overall summary",
        body: "No data available",
      };
    }
    const content = (
      <>
        {analysisData?.combinedResponse?.readable_rca && (
          <ReadableRootCause 
            readableRCA={analysisData.combinedResponse.readable_rca} 
            referenceClickHandler={scrollToModuleIndx} 
          />
        )}
        {analysisData?.combinedResponse?.identifier_map && (
          <IdentifierMapView identifierMap={analysisData.combinedResponse.identifier_map} />
        )}
      </>
    );

    return {
      title: "Overall summary",
      body: content,
    };
  }
  
  const content = getCardContent();

  return (
    <CollapsibleCard 
      title={content.title}
    >
      {content.body}
      <FeedbackButtons
            feedbackState={feedbackState}
            handleFeedback={handleFeedback}
          />
    </CollapsibleCard>
  );
};


export const AnalysisDataViewV2 = (props) => {
    const [userHint, setUserHint] = useState('');
    const [isInterrupting, setIsInterrupting] = useState(false);
    const [isPrompting, setIsPrompting] = useState(false);
  
    useEffect(() => {
      if (props.analysisData && props.analysisData.state === 'INTERRUPTING') {
        setIsInterrupting(true);
      } else {
        setIsInterrupting(false);
      }
  
    }, [props.analysisData]);
  
    const handleInterrupt = () => {
      setIsInterrupting(true);
      props.onInterrupt().then(() => {
        //setIsInterrupting(false);
      }).catch(() => {
        setIsInterrupting(false);
      });
    };
  
    const handlePrompt = () => {
      setIsPrompting(true);
      const result = props.onUserPrompt(userHint);
      Promise.resolve(result)
        .then(() => {
          setIsPrompting(false);
          setUserHint("");
        })
        .catch((error) => {
          console.error("Error in onUserPrompt:", error);
          setIsPrompting(false);
        });
    };
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handlePrompt();
      }
    };
  
    const scrollToModuleIndx = (moduleIndx) => {
      const element = document.getElementById(`module-${moduleIndx}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
    if (!props.analysisData) {
      return (
        <div>
          <Button
              variant="success"
              type="submit"
              className="align-self-end" onClick={props.onRunAnalysis}>Run Analysis</Button>
        </div>
        );
    }

    return (
      <div>
        <RCASection
          analysisData={props.analysisData}
          issueId={props.issue.id}
          scrollToModuleIndx={scrollToModuleIndx}
        />
  
      {/* {props.analysisData && props.analysisData.relatedIssueData && props.analysisData.relatedIssueData.length > 0 && (<SimilarIssues analysisData={props.analysisData} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />)} */}
      {/* {props.analysisData && props.analysisData.identifiersToAskFor && props.analysisData.identifiersToAskFor.length > 0 && (
        <>
        <RequestingInfo analysisData={props.analysisData} onUserPrompt={props.onUserPrompt} />
      </>)
      } */}
  
      {props.analysisData.moduleOutputs.map((moduleOutput, index) => (
        <ModuleAnalysisView key={index} moduleOutput={moduleOutput} />
      ))}
  
      {/* Spinner for ongoing analysis */}
      {props.analysisData.stillRunning && (<div className="d-flex justify-content-between align-items-center mt-3">
        <div className="flex-grow-1">
          {props.analysisData.state === 'IN_PROGRESS' && (
            <div className="d-flex align-items-center">
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <span style={{ color: '#5935c9', marginLeft: '8px' }} className="ms-2 font-size-085">{props.analysisData.progressData?.progress_str}</span>
            </div>
          )}
        </div>
        {/* <button 
          className="btn btn-outline-danger ms-3" 
          onClick={handleInterrupt}
          disabled={isInterrupting}
        >
          {isInterrupting ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Stopping...
            </>
          ) : (
            'Stop Analysis'
          )}
        </button> */}
      </div>)}
  
      {/* Prompt for user input */}
      {(
        <div className="card my-3">
          <div className="row mx-3 my-3">
            <div className="col-md-11">
              <input 
                type="text" 
                placeholder="Ask Relvy to look at other logs or metrics..." 
                className="form-control" 
                id="alsoLookAt" 
                value={userHint} 
                onChange={(e) => setUserHint(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-md-1">
              <button 
                type="submit" 
                className="btn btn-primary custom-btn m-0"
                style={{
                  backgroundColor: '#5935c9',
                  borderColor: '#5935c9',
                  color: 'white',
                  transition: 'background-color 0.3s, color 0.3s'
                }}
                onClick={handlePrompt}
                disabled={isPrompting}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>);
  };
